import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap";

export default class extends Controller {
  connect() {
    if (!this.myTooltip) {
      this.myTooltip = new bootstrap.Tooltip(this.element, {
        html: true,
        container: "body",
        placement: "top",
        title: this.element.dataset.title,
      });
    }
  }

  disconnect(){
    if (this.myTooltip) {
      this.myTooltip.dispose();//it should be implemented to avoid memory leaks.
      this.myTooltip = null; // Reset the instance
    }
  }

  dismiss() {
  }

  show(){
    this.myTooltip.toggle();
  }

  showOnly(){
    if (this.myTooltip) {
      this.myTooltip.show();
    }
  }

  hide(){
    if (this.myTooltip) {
      this.myTooltip.hide();
    }
  }
}