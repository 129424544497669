import { Controller } from "@hotwired/stimulus";

// Shared singleton state
const sharedState = {
  tables: [],
  tableHeaders: [],
  tableIcons: [],
  targetColumn: null,
  hideTooltipTimeout: null,
  currentTooltipController: null, // Move currentTooltipController to shared state

  update() {
    this.tables = document.querySelectorAll(".medication-table-js");
    this.tableHeaders = document.querySelectorAll(".table-optimizer-summary");
    this.tableIcons = Array.from(
        document.querySelectorAll(".section-optimizer .table-responsive .table-wrap th[data-col]")
    );
  },

  get latestTables() {
    return this.tables;
  },

  get latestTableHeaders() {
    return this.tableHeaders;
  },

  get latestTableIcons() {
    return this.tableIcons;
  },
  get currentTargetColumn() {
    return this.targetColumn;
  },
  set currentTargetColumn(col) {
    this.targetColumn = col;
  },
  get TooltipTimeout() {
    return this.hideTooltipTimeout;
  },
  set TooltipTimeout(timeout) {
    this.hideTooltipTimeout = timeout;
  },
  get CurrentTooltipController() {
    return this.currentTooltipController;
  },
  set CurrentTooltipController(controller) {
    this.currentTooltipController = controller;
  },
};

export default class extends Controller {
  static targets = ["cell"];

  connect() {
    sharedState.update();

    // Add overflow-visible class to relevant elements
    document
        .querySelectorAll(".section-optimizer .table-responsive, .section-optimizer .table-wrap")
        .forEach((element) => element.classList.add("overflow-visible"));
  }

  highlight(event) {
    const hoveredCell = event.currentTarget;
    const row = hoveredCell.dataset.row;
    const col = hoveredCell.dataset.col;

    // Cancel any pending tooltip hiding timeout
    if (sharedState.TooltipTimeout) {
      clearTimeout(sharedState.TooltipTimeout);
      sharedState.TooltipTimeout = null;
    }

    if (sharedState.currentTargetColumn !== col) {
      this.clearTranslateEffects();
      if (sharedState.CurrentTooltipController) {
        sharedState.CurrentTooltipController.hide();
        sharedState.CurrentTooltipController = null;
      }
      sharedState.currentTargetColumn = col;
      this.addTranslateClass(col);
    }

    sharedState.latestTableHeaders.forEach((table) => {
      table.classList.add("smooth-visibility", "invisible");
    });

    sharedState.latestTables.forEach((table) => {
      const colCells = table.querySelectorAll(`td[data-col="${col}"]`);

      colCells.forEach((cell) => cell.classList.add("table-striped-columns"));
      colCells.forEach((cell) => {
        if (cell.dataset.row === row && cell.dataset.col === col) {
          cell.classList.add("table-striped-columns-full");
        }
      });
    });
  }

  addTranslateClass(colNumber) {
    const targetElement = sharedState.latestTableIcons.find(
        (icon) => icon.dataset.col === colNumber
    );

    if (targetElement && !targetElement.classList.contains("move-down-translate-effect")) {
      targetElement.classList.add("move-down-translate-effect");
      this.showTooltip(targetElement);
    }
  }

  showTooltip(element) {
    const tooltipController = this.application.getControllerForElementAndIdentifier(
        element,
        "tooltip"
    );
    if (tooltipController) {
      sharedState.CurrentTooltipController = tooltipController;
      tooltipController.showOnly();
    }
  }

  clearTranslateEffects() {
    sharedState.latestTableIcons.forEach((icon) => {
      icon.classList.remove("move-down-translate-effect");
    });
  }

  clearHighlights(event) {
    sharedState.latestTables.forEach((table) => {
      table.querySelectorAll("td").forEach((cell) => {
        cell.classList.remove("table-striped-columns", "table-striped-columns-full");
      });
    });


    this.reset();
  }

  reset() {
    sharedState.TooltipTimeout = setTimeout(() => {
      sharedState.latestTableHeaders.forEach((table) => {
        table.classList.remove("invisible");
        table.classList.add("smooth-visibility");
      });
      this.clearTranslateEffects();
      sharedState.currentTargetColumn = null;
      if (sharedState.CurrentTooltipController) {
        sharedState.CurrentTooltipController.hide();
        sharedState.CurrentTooltipController = null;
      }

    }, 300); // Adjust delay time as needed
  }

  disconnect() {
    this.clearHighlights();

    document
        .querySelectorAll(".section-optimizer .table-responsive, .section-optimizer .table-wrap")
        .forEach((element) => element.classList.remove("overflow-visible"));
  }
}